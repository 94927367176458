import Analytics from './analytics.js';
import { button } from './analytics.config.js';

export default class ButtonAnalytics extends Analytics {
  trackButtonClick() {
    const { title } = this.el;
    const event = {
      event: button.events.button,
      eventAction: title,
      eventLabel: title,
      url: this.el.href,
    };
    this.pushAnalytics(event);
  }
}
