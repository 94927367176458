import { register } from '@netcentric/component-loader';
import ButtonAnalytics from 'commons/libs/analytics/analytics.button.js';

class Button {
  constructor(el) {
    this.el = el;
    this.init();
  }

  init() {
    this.buttonAnalytics = new ButtonAnalytics(this.el);
    this.el.addEventListener('click', () => {
      this.buttonAnalytics.trackButtonClick();
    });
  }
}

register({ Button });
